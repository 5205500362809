import React, { createContext, useEffect, useState } from 'react';
import cookie from "js-cookie";
import axios from 'axios'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const updateUser = (data) => {
    setUser(data)
  }
  
  const login = async() => {
    const roleId = cookie.get("roleId");
    let token = cookie.get("token");
    if(roleId == 1){
      token = cookie.get("stoken");
    }
    if(roleId == 12){
      token = cookie.get("estoken");
    }
    const clubId = cookie.get("clubId");
    if (token && clubId && roleId) {
      await axios.get(`/api/global/system/validateUser?id=${clubId}&roleId=${roleId}`, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((resp) => {
        const data = resp.data.result ? resp.data.result : null;
        setUser(data);
      });
    }else{
      // alert('not found')
    }
  };
  
  useEffect(()=>{
    login()
  },[])

  const logout = () => {
    setUser(null);
    cookie.remove("token");
    cookie.remove("clubId");
    cookie.remove("roleId");
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider value={{ user, updateUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
